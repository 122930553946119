//type
import { BUILDING_TYPE } from '@components/lib/interfaces/config/graphql/API';
import {
    FormData,
    SetFieldValueFn,
    UseControllersProps,
    UseControllersReturn,
    UseInvalidAddressPopupCtrlReturn,
    UseMainCtrlReturn,
    UseMainFormCtrlReturn,
    UseBuildingTypePopupCtrlReturn
} from '@components/home/main/interfaces/app/controllers/useControllers';
import { AddressData } from '@components/lib/interfaces/model/models/AddressData';

//library
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//store
import {
    next,
    setQuote,
    setTooglePopup,
    setError,
    reset,
    setQuestion,
    setSet,
    setUtm
} from '@components/store/slices/simulator/simulatorSlice';
import selectedQuoteType from '@components/store/slices/simulator/selectors/quoteTypeSelector';
import selectedQuestion from '@components/store/slices/simulator/selectors/questionSelector';
import selectedQuote from '@components/store/slices/simulator/selectors/quoteSelector';
import selectedPopup from '@components/store/slices/simulator/selectors/popupSelector';
import selectedSet from '@components/store/slices/simulator/selectors/setSelector';
import selectedDirection from '@components/store/slices/simulator/selectors/directionSelector';
import sendMixpanelEvent from '@components/lib/helper/sendMixpanelEvent';

/**
 * The controllers
 *
 * @param {UseControllersProps} props - The props.
 * @returns {UseControllersReturn} - The controllers.
 */
const useControllers = ({
    model: { homeModel },
    lib: {
        external: { router }
    }
}: UseControllersProps): UseControllersReturn => {
    const dispatch = useDispatch();
    const quote = useSelector(selectedQuote);
    const quoteType = useSelector(selectedQuoteType);
    const question = useSelector(selectedQuestion);
    const set = useSelector(selectedSet);
    const direction = useSelector(selectedDirection);
    const popup = useSelector(selectedPopup);

    const { countryCode } = homeModel;
    const map = homeModel.getMap(countryCode);

    const { query } = router;
    /**
     * The controller for Main module
     *
     * @returns {UseMainCtrlReturn} - The main functions
     */
    const useMainCtrl = (): UseMainCtrlReturn => {
        useEffect(() => {
            if (question?.input === 'address') {
                return;
            }

            dispatch(setQuestion({ label: 'search', input: 'address', type: 'map' }));
        }, []);

        return {
            map
        };
    };

    /**
     * The controller for Main Form module
     *
     * @returns {UseMainFormCtrlReturn} - The main form functions
     */
    const useMainFormCtrl = (): UseMainFormCtrlReturn => {
        /**
         * Set formik value of building type when the select change.
         *
         * @param {SetFieldValueFn} setFieldValue - The function to set the field value in the formik
         * @returns {void}
         */
        const onBuildingTypeChange =
            (setFieldValue: SetFieldValueFn): ((value: string) => void) =>
            (value) => {
                setFieldValue('type', value);
            };

        /**
         * Set formik value of address when the value of suggesstion change.
         *
         * @param {SetFieldValueFn} setFieldValue - The function to set the field value in the formik
         * @returns {(value: AddressData) => void} - The function to handle address changes
         */
        const onAddressChange =
            (setFieldValue: SetFieldValueFn): ((value: AddressData) => void) =>
            (value) => {
                setFieldValue('lat', value.lat);
                setFieldValue('lng', value.lng);
                setFieldValue('address', value.address);
                setFieldValue('address_components', value.address_components);
            };

        /** Show the the toggle invalid address popup  */
        const handleSearchInvalidAddress = () => {
            dispatch(
                setTooglePopup({
                    popup: 'invalidAddress',
                    open: true
                })
            );
        };

        /**
         * The function to update the quote in state after submitting
         *
         * @param {AddressData} values - The address data
         * @returns {void}
         */
        const handleSubmit = (values: AddressData) => {
            // reset everything
            dispatch(reset());

            // set device
            const MAX_WIDTH = 768;
            dispatch(setSet(window.innerWidth >= MAX_WIDTH ? 'desktop' : 'mobile'));

            dispatch(
                setQuote({
                    lat: values.lat,
                    lng: values.lng,
                    address: values.address,
                    address_components: values.address_components,
                    type: values.type
                })
            );

            if (
                values.type === BUILDING_TYPE.CONDOMINIUM ||
                values.type === BUILDING_TYPE.HDB_FLAT
            ) {
                dispatch(
                    setTooglePopup({
                        popup: 'buildingType',
                        open: true
                    })
                );

                return;
            }

            dispatch(next());

            //send event to mixpanel
            sendMixpanelEvent(`Step: ${question?.input}`, {
                quote: {
                    ...quote,
                    lat: values.lat,
                    lng: values.lng,
                    address: values.address,
                    address_components: values.address_components,
                    type: values.type
                },
                set,
                direction,
                utm: router.asPath.split('?')[1]
            });

            //set utm
            dispatch(
                setUtm({
                    source: query?.utm_source as string,
                    medium: query?.utm_medium as string,
                    campaign: query?.utm_campaign as string,
                    term: query?.utm_term as string,
                    content: query?.utm_content as string
                })
            );

            router.push(`/question`);
        };

        return {
            onBuildingTypeChange,
            onAddressChange,
            handleSubmit,
            handleSearchInvalidAddress,
            quote,
            countryCode
        };
    };

    /**
     * The controller for invalid address popup
     *
     * @returns {UseInvalidAddressPopupCtrlReturn} - The invalid address popup functions
     */
    const useInvalidAddressPopupCtrl = (): UseInvalidAddressPopupCtrlReturn => {
        /**
         * Show / hide toggle invalid address popup
         *
         * @param {boolean} open - Value to open / hide popup.
         * @returns {() => void} - The function for toggle invalid address
         */
        const toggleInvalidAddressPopup = (open: boolean): (() => void) => {
            return () => {
                dispatch(
                    setTooglePopup({
                        popup: 'invalidAddress',
                        open
                    })
                );
            };
        };

        return {
            toggleInvalidAddressPopup,
            popup
        };
    };

    /**
     * The controller for Building type module
     *
     * @returns {UseBuildingTypePopupCtrlReturn} - The bulding type popup functions
     */
    const useBuildingTypePopupCtrl = (): UseBuildingTypePopupCtrlReturn => {
        /**
         * Show / hide toggle popup
         *
         * @param {boolean} open - Value to open / hide popup.
         * @returns {() => void} - The function to open / close  popup
         */
        const toggleBuildingTypePopup = (open: boolean): (() => void) => {
            return () => {
                dispatch(
                    setTooglePopup({
                        popup: 'buildingType',
                        open
                    })
                );
            };
        };

        /**
         * The function to send email data to slack
         *
         * @param {FormData} values - The email
         * @returns {void}
         */
        const handleSubmit = async (values: FormData) => {
            if (values.email) {
                try {
                    await homeModel.email(values.email);
                } catch (error) {
                    dispatch(
                        setError({
                            message: 'Error when send email data'
                        })
                    );

                    return;
                }
            }

            dispatch(
                setTooglePopup({
                    popup: 'buildingType',
                    open: false
                })
            );
            dispatch(next());
            router.push(`/question`);
        };

        return {
            handleSubmit,
            toggleBuildingTypePopup,
            popup,
            quoteType
        };
    };

    return {
        useMainCtrl,
        useMainFormCtrl,
        useInvalidAddressPopupCtrl,
        useBuildingTypePopupCtrl
    };
};

export default useControllers;
