//type
import { HomeConfig } from '@components/home/main/interfaces/Home';

//library
import React, { ReactElement } from 'react';

//code
import Search from '@components/home/search/Search';
import InvalidAddresPopup from '@components/home/main/app/view/layout/InvalidAddressPopup';
import BuildingTypePopup from '@components/home/main/app/view/layout/BuildingTypePopup';
import { COUNTRY_CODE } from '@components/lib/interfaces/config/graphql/API';

/**
 * Home.
 *
 * @param {HomeConfig} config - The config
 * @returns {ReactElement} - The the map
 */
const MainForm: React.FC<HomeConfig> = (config: HomeConfig): ReactElement => {
    const {
        module: {
            input: { Select }
        },
        list: { listBuilding: getListBuilding },
        external: {
            Formik: { Formik, Form, ErrorMessage },
            Yup
        }
    } = config.lib;
    const {
        onBuildingTypeChange,
        onAddressChange,
        handleSubmit,
        handleSearchInvalidAddress,
        quote,
        countryCode
    } = config.controller.useMainFormCtrl();

    const initialValues = {
        lat: quote.lat,
        lng: quote.lng,
        address: quote.address,
        address_components: quote.address_components,
        type: quote.type
    };

    const listBuilding = getListBuilding(countryCode);

    let invalidAddressError = '';

    if (countryCode === COUNTRY_CODE.SG) {
        invalidAddressError = 'Please select a valid address or postal code.';
    }

    if (countryCode === COUNTRY_CODE.MY) {
        invalidAddressError = 'Please select a valid address';
    }

    return (
        <>
            <InvalidAddresPopup {...config} />
            <BuildingTypePopup {...config} />
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                    lat: Yup.number().required(invalidAddressError),
                    lng: Yup.number().required(invalidAddressError),
                    address: Yup.string().required(invalidAddressError),
                    address_components: Yup.array(),
                    type: Yup.string().required('Please select an option')
                })}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleSubmit}
                enableReinitialize>
                {({ values, setFieldValue, handleSubmit }) => (
                    <Form className="flex h-full">
                        <div className="m-auto px-8 md:px-10 py-10 w-[calc(100% - 32px)] md:w-[432px] rounded-[20px] bg-white">
                            <div className="text-center text-contrast-blue font-bold mb-10">
                                <h1 className="text-[24px] leading-[31.2px]  md:text-[32px] md:leading-[41.6px] mb-2">
                                    Solar Panel Calculator
                                </h1>
                                <div className="text-[20px] leading-[26px] md:text-[24px] md:leading-[31.2px]">
                                    Get your solar quote in just
                                </div>
                                <div className="text-[20px] leading-[26px]  md:text-[24px] md:leading-[31.2px]">
                                    under a minute
                                </div>
                            </div>
                            <div className="w-full items-center mb-0.5 md:mb-2">
                                <div className="font-medium text-[16px] leading[22.4px] md:font-semibold md:text-[16px] md:leading-[19.36px]">
                                    My property is a
                                </div>
                            </div>
                            <div className="w-full items-center mb-5">
                                <Select
                                    data={{
                                        field: 'type',
                                        label: 'building',
                                        placeholder: 'Select option',
                                        options: listBuilding,
                                        isMulti: false,
                                        closeMenuOnSelect: true,
                                        onChosen: onBuildingTypeChange(setFieldValue)
                                    }}
                                    lib={config.lib}
                                />
                                <div className="text-red-500 text-xs italic absolute">
                                    <ErrorMessage name="type" />
                                </div>
                            </div>
                            <div className="w-full items-center mb-0.5 md:mb-2">
                                <div className="font-medium text-[16px] leading[22.4px] md:font-semibold md:text-[16px] md:leading-[19.36px]">
                                    Located at
                                </div>
                            </div>
                            <div className="w-full items-center mb-10">
                                <Search
                                    lib={{
                                        external: {
                                            Formik: config.lib.external.Formik,
                                            Geosuggest: config.lib.external.Geosuggest
                                        }
                                    }}
                                    data={{
                                        countryCode,
                                        field: 'address',
                                        onChange: onAddressChange(setFieldValue)
                                    }}
                                />
                                <div className="text-red-500 text-xs italic absolute">
                                    <ErrorMessage name="lat" />
                                </div>
                            </div>
                            <div>
                                <button
                                    aria-label="start-survey"
                                    type="button"
                                    onClick={() => {
                                        if (values.address && (!values.lat || !values.lng)) {
                                            handleSearchInvalidAddress();
                                        }

                                        handleSubmit();
                                    }}
                                    className={`flex items-center justify-center px-4 h-11 w-full font-bold rounded-md bg-sunglow text-contrast-blue`}>
                                    <span className="font-semibold text-[16px] leading-[20px]">
                                        Start survey
                                    </span>
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default MainForm;
