//type
import { BUILDING_TYPE, COUNTRY_CODE } from '@components/lib/interfaces/config/graphql/API';

//code
import { List, SelectOption } from '@components/lib/interfaces/index';

export const building: List<SelectOption<string>> = {
    [BUILDING_TYPE.LANDED_HOUSE]: {
        value: BUILDING_TYPE.LANDED_HOUSE,
        label: 'Landed house'
    },
    [BUILDING_TYPE.CONDOMINIUM]: {
        value: BUILDING_TYPE.CONDOMINIUM,
        label: 'Condominium/Strata'
    },
    [BUILDING_TYPE.HDB_FLAT]: {
        value: BUILDING_TYPE.HDB_FLAT,
        label: 'HDB flat'
    },
    [BUILDING_TYPE.RENTED_COMMERCIAL_PROPERTY]: {
        value: BUILDING_TYPE.RENTED_COMMERCIAL_PROPERTY,
        label: 'Rented commercial property'
    },
    [BUILDING_TYPE.OWNED_COMMERCIAL_PROPERTY]: {
        value: BUILDING_TYPE.OWNED_COMMERCIAL_PROPERTY,
        label: 'Owned commercial property'
    }
};

/**
 *
 * @param countryCode
 */
export function listBuilding(countryCode: COUNTRY_CODE) {
    if (countryCode === COUNTRY_CODE.MY) {
        return Object.values(building).filter((b) => b.value !== BUILDING_TYPE.HDB_FLAT);
    }

    return Object.values(building);
}
