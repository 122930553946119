//type
import { SearchConfig } from '@components/home/search/interfaces/Search';
import { COUNTRY_CODE } from '@components/lib/interfaces/config/graphql/API';

//library
import React, { ReactElement } from 'react';

/**
 * The search input.
 *
 * @param {SearchConfig} config - The config.
 * @returns {ReactElement} - The search input.
 */
const Main: React.FC<SearchConfig> = (config: SearchConfig): ReactElement => {
    const { Geosuggest } = config.lib.external;
    const { countryCode, ref, value, error, onSuggestSelect, onSuggestInputChange } =
        config.controller.useMainCtrl();

    let placeholder = 'Enter your address or postal code';
    if (countryCode === COUNTRY_CODE.MY) {
        placeholder = 'Enter your full address';
    }

    return (
        <div
            className={`flex ${
                error ? 'border-[#FF6262] bg-[#FFE0E0]' : 'border-[#345995] border-opacity-25'
            }  border rounded-lg items-center pl-3 pr-1.5`}>
            <Geosuggest
                ref={ref}
                initialValue={value}
                country={[countryCode]}
                className={`w-full relative`}
                inputClassName={'bg-transparent h-11 w-full outline-none'}
                suggestsClassName={'absolute bg-white z-10 w-full mt-0.5 white border rounded'}
                suggestsHiddenClassName={'hidden'}
                suggestItemClassName={'py-2 px-3 cursor-pointer text-[#016FB9] border'}
                autoComplete={'off'}
                placeholder={placeholder}
                onSuggestSelect={onSuggestSelect}
                onChange={onSuggestInputChange}
                autoActivateFirstSuggest={true}
            />
        </div>
    );
};

export default Main;
